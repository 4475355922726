import '@css/main.scss';

import {Header} from './utils/Header'
import {Tablist} from "./utils/Tablist";
import {MouseMovingElement} from "./utils/MouseMovingElement";
import {Slider} from "./utils/Slider";
import {Tabbuttons} from "./utils/Tabbuttons"
import {titleAnim} from "./utils/titleAnim";
import {scrollAnim} from "./utils/scrollAnim";

window.onload = () => {

  new titleAnim()
  new scrollAnim()
  new Header()
  new MouseMovingElement()
  new Slider()
  new Tabbuttons()

  setTimeout(() => {
    new Tablist()
  }, 250)

}

