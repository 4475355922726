import Swiper, {Pagination, Autoplay, Navigation} from "swiper";

export class Slider {
  constructor() {

    document.querySelectorAll('*[slider]').forEach(slider => {

      this.registerSlider(slider)

    })
  }

  registerSlider(slider) {

    let name = slider.getAttribute('data-slider')
    let per_view = slider.getAttribute('per-view')
    let per_view_sm = slider.getAttribute('per-view-sm')
    let nav = slider.getAttribute('navigation')

    const modules = [Pagination, Autoplay]
    let navigation = {}
    let pagination = {}

    const
      swiper = new Swiper('.' + name, {
        modules: modules,
        slidesPerView: per_view,
        loop: false,
        spaceBetween: 20,
        autoplay: {
          delay: 2500
        },
        breakpoints: {
          300: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4
          },
          1100: {
            slidesPerView: 5
          }
        }
      });

  }
}
