import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export class scrollAnim {

  constructor() {

    let mm = gsap.matchMedia()
    gsap.registerPlugin(ScrollTrigger);

    gsap.timeline({})

    /** Animation Image Home **/

    mm.add("(min-width: 768px)", () => {

    gsap.timeline({
      scrollTrigger: {
        trigger: '.s-media[animated]',
        start: "top-=100 center",
        end: "bottom center",
        scrub: 1,
      },
    })
      .to('.s-media[animated] .c-image', {
        top: "0",
        duration: 0.5
      })
      .to('.s-media[animated] .c-image', {
        width: "100%",
        marginBottom: "0",
        height: "1000px",
        duration: 0.5,
      })

    })

    /***********************/

    /** Animation Reset position Hero **/

    gsap.to('.s-hero', {
      scrollTrigger: {
        trigger: '.s-tablist',
        start: "top top-=50",
        scrub: true,

      },
      position: "static",

    })

    /***********************/

    /** Animation Bloc équipe **/

    mm.add("(min-width: 1100px)", () => {

      gsap.timeline({
        scrollTrigger: {
          trigger: '.s-team',
          start: "top center",
          end: "top top",
          scrub: 1,
        }
      })
        .to('.s-team', {
          backgroundColor: "#282828"
        })
        .from('.s-team .team-bubble', {
          left: "50%",
          top: "50%"
        })

      gsap.timeline({
        scrollTrigger: {
          trigger: '.s-team',
          start: "center top",
          end: "bottom top+=200",
          scrub: 1
        }
      })
        .to('.s-team', {
          backgroundColor: "#FCFCFC"
        })

    })


    /***********************/

    /** Animation Dégradé home **/

    gsap.to('.home .s-content', {
      scrollTrigger: {
        trigger: '.home .s-content',
        start: "center+=200 top",
        end: "bottom top",
        scrub: 1
      },
      backgroundColor: "#FCFCFC"
    })

    /***********************/

    /** Animation Parallax **/

    const worksSection = document.querySelector('.works[parallax]')

    mm.add("(min-width: 430px)", () => {

      gsap.to(".works[parallax] .card", {
        y:
          (i, el) => (-1 * parseFloat(el.getAttribute("data-speed"))) * (worksSection.offsetHeight / 12),
        scrollTrigger: {
          trigger: worksSection,
          scrub: 0,
          ease: "power3",
          invalidateOnRefresh: true,
          start: 'top bottom'
        }
      });

    })


  }
}
