export class Tabbuttons {

  constructor() {

    const tabList = document.querySelector('.tab-buttons')

    if (tabList) {
      this.init()
    }

  }

  init() {
    const tabButtons = document.querySelectorAll('.tab-button')

    tabButtons.forEach(button => {

      button.addEventListener("click", (e) => {

        tabButtons.forEach(button => {
          button.classList.remove('active')
        })

        button.classList.add('active')

        const tabID = button.getAttribute('data-tab')
        const panels = document.querySelectorAll('.tab-panel')

        panels.forEach(panel => {
          panel.classList.remove('active')
        })

        const panelToActive = document.querySelector('.tab-panel[data-panel="' + tabID + '"]')
        panelToActive.classList.add('active')

      })

    })
  }

}
