import SplitType from "split-type";
import gsap from "gsap";

export class titleAnim {

  constructor() {

    const title = document.querySelector('.split')

    if (title) {

      let typeSplit = new SplitType("[text-split]", {
        types: "words, chars",
        tagName: "span"
      });

      let tl = gsap.timeline();

      const chars = title.querySelectorAll('.char')

      tl.from(chars, {
        yPercent: -120,
        delay: 0.4,
        duration: 0.5,
        ease: "power1.out",
        stagger: 0.03
      });

    }
  }
}
