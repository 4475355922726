export class Tablist {

  constructor() {

    const tabList = document.querySelector('.tablist')

    if (tabList) {
      this.init()
      this.updateMaxHeight()
      window.addEventListener("resize", (e) => {
        this.updateMaxHeight()
      })
    }
  }

  init() {
    const tabButtons = document.querySelectorAll('.tab-button')

    tabButtons.forEach(button => {

      button.addEventListener("click", (e) => {

        tabButtons.forEach(button => {
          button.classList.remove('active')
        })

        button.classList.add('active')

        const tabID = button.getAttribute('data-tab')
        const panels = document.querySelectorAll('.tab-panel')

        panels.forEach(panel => {
          panel.classList.remove('active')
        })

        const panelToActive = document.querySelector('.tab-panel[data-panel="' + tabID + '"]')
        panelToActive.classList.add('active')

        if (window.matchMedia("(max-width: 900px)").matches) {

          panelToActive.scrollIntoView()

        }

      })

    })
  }

  updateMaxHeight() {

    const tabList = document.querySelector('.tablist')
    const tabButtons = document.querySelectorAll('.tab-button')
    let totalHeight = 0

    tabButtons.forEach(t => {
      totalHeight += t.offsetHeight
    })
    tabList.style.setProperty('--max-height', totalHeight + 'px')
  }

}
